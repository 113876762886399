@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body,
#root {
  @apply h-full w-full overflow-hidden;
}

#root {
  @apply scroll-smooth;
}

.simplebar-content {
  @apply h-full;

  .simplebar-content-wrapper {
    @apply outline-none;
  }
}

@layer utilities {
  .bg-glass {
    background: linear-gradient(
      100.13deg,
      rgba(255, 255, 255, 0.08) -7.77%,
      rgba(255, 255, 255, 0.22) 126.5%
    );
    backdrop-filter: blur(25px);
  }

  .border-glass {
    border-width: 1px;
    border-color: #b1b0ba;
  }
}

.qr-code-scanner {
  @apply h-full w-full;

  div {
    @apply !h-full;

    video {
      @apply !w-auto !max-w-none;
    }
  }
}
