@tailwind components;

@layer components {
  .checkbox-container {
    @apply relative h-fit w-fit;

    .icon {
      @apply pointer-events-none absolute top-2/4 left-2/4 mt-0.5 !h-3 !w-3 -translate-x-2/4 -translate-y-2/4 fill-transparent stroke-white !p-0;
    }

    .checkbox {
      @apply h-5 w-5 shrink-0 cursor-pointer appearance-none rounded bg-white align-middle text-secondary shadow-md ring-1 ring-light-grey;

      &:focus-visible {
        @apply outline-1 outline-offset-4 outline-[#EEEEEE] ring-secondary;
      }

      &:checked {
        @apply bg-secondary ring-secondary;
      }
    }

    &.error {
      .checkbox {
        @apply ring-error;
      }
    }

    &.disabled {
      .checkbox {
        @apply pointer-events-none bg-[#E2E2E2] ring-0;
      }
    }
  }
}
