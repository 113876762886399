@tailwind components;

@layer components {
  .btn {
    @apply flex h-11 w-full cursor-pointer items-center justify-center space-x-4 rounded-[0.375rem] font-medium transition-colors;

    &.icon {
      @apply w-11;
    }

    &:focus-visible {
      @apply outline-2 outline-offset-4 outline-[#EEEEEE];
    }

    &.primary {
      @apply bg-secondary text-white active:bg-dark-blue-200;

      &:hover {
        @apply bg-dark-blue-100;
      }

      &.disabled {
        @apply bg-light-blue-400;
      }

      .icon {
        @apply fill-white;
      }
    }

    &.secondary {
      @apply bg-white text-primary ring-1 ring-primary active:bg-dark-blue-200;

      &:hover {
        @apply bg-dark-blue-100 text-white;

        .icon {
          @apply fill-white;
        }
      }

      &.disabled {
        @apply bg-white text-light-blue-400 ring-light-blue-400;

        .icon {
          @apply fill-light-blue-400 text-light-blue-400;
        }
      }

      .icon {
        @apply fill-accent;
      }
    }

    &.ghost {
      @apply h-fit w-fit p-1;

      &:hover {
        @apply bg-[#EEEEEE];
      }
    }
  }
}
