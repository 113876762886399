@tailwind components;

@layer components {
  .input-container {
    @apply relative;

    .input-element {
      @apply absolute top-2/4 flex h-9 w-9 origin-top-left -translate-y-2/4 items-center justify-center;

      &.left {
        @apply left-0;
      }

      &.right {
        @apply right-0;

        .btn {
          @apply right-2;

          .icon {
            @apply h-4 w-4;
          }
        }
      }
    }

    .input {
      @apply w-full rounded-[0.375rem] bg-white py-2 px-3 text-sm text-dark-blue-500 shadow-md ring-1 ring-[#EEEEEE] placeholder:text-dark-blue-500/50 hover:ring-secondary focus:outline-none;

      &.with-element-left {
        @apply pl-9;
      }

      &.with-element-right {
        @apply pr-9;
      }

      &.disabled {
        @apply bg-[#E2E2E2] ring-0;
      }

      &.error {
        @apply !ring-error;
      }

      &.success {
        @apply !ring-success;
      }

      &:focus-visible {
        @apply outline-2 outline-offset-4 outline-[#EEEEEE] ring-secondary;
      }
    }
  }
}
